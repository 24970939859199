import React from 'react';

const SecurityIcon = ({ isActive = true, variant = 'light' }) => (
  <svg width="32" height="34" viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8181 0.100098C15.8181 0.100098 18.3181 0.600098 23.8181 2.0001C29.0181 3.3001 30.8181 3.9001 30.8181 3.9001C30.8181 3.9001 31.7181 9.1001 29.3181 18.8001C27.4181 26.6001 21.2181 31.6001 15.8181 33.5001C10.4181 31.6001 4.21814 26.6001 2.31814 18.8001C-0.0818619 9.1001 0.818138 3.9001 0.818138 3.9001C0.818138 3.9001 2.61814 3.4001 7.81814 2.0001C13.3181 0.600098 15.8181 0.100098 15.8181 0.100098Z"
      fill={
        isActive
          ? '#4D8EF8'
          : (variant === 'light' && '#5C5E7B') || (variant === 'dark' && '#898B9E')
      }
    />
    <path
      d="M15.818 0C18.318 0.4 20.918 0.9 23.518 1.4C24.818 1.7 26.018 2 27.318 2.3L31.118 3.2L31.618 3.3L31.718 3.8C32.118 7 31.918 10 31.418 13C31.218 14.5 30.818 16 30.418 17.4C30.018 18.8 29.618 20.3 29.018 21.7C27.718 24.5 25.918 27 23.718 29C21.518 31.1 19.018 32.8 16.118 33.9L16.018 34L15.818 33.9C12.918 33 10.118 31.4 7.91803 29.3C5.61803 27.2 3.81802 24.6 2.51802 21.8C1.91802 20.4 1.51803 18.8 1.21803 17.4C0.918035 15.9 0.618032 14.4 0.418032 12.9C0.0180319 9.9 -0.181965 6.9 0.218035 3.7L0.318026 3.2L0.818026 3.1C3.31803 2.5 5.81803 1.9 8.41803 1.3C10.718 0.9 13.318 0.4 15.818 0ZM15.818 0.2C13.318 1 10.818 1.7 8.41803 2.4C7.21803 2.8 5.91804 3.2 4.71804 3.5L1.01802 4.6L1.51802 4C1.41802 5.4 1.41802 6.9 1.51802 8.3C1.61802 9.8 1.81802 11.2 2.01802 12.7C2.51802 15.6 3.01802 18.6 4.01802 21.2C5.01802 23.9 6.71803 26.3 8.81803 28.3C10.918 30.3 13.318 32 16.018 33.1H15.718C18.518 32.2 21.118 30.7 23.318 28.7C25.518 26.7 27.218 24.2 28.118 21.4C29.018 18.7 29.418 15.7 29.818 12.7C30.018 11.2 30.118 9.8 30.218 8.3C30.318 6.9 30.318 5.3 30.218 4L30.718 4.6L27.018 3.5L23.318 2.4C20.818 1.7 18.318 0.9 15.818 0.2Z"
      fill="#33355A"
    />
  </svg>
);

export default SecurityIcon;
